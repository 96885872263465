.SideMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  width: 104px;
  background: var(--white);
  filter: drop-shadow(2px 0px 8px rgba(33, 33, 33, 0.1));
  z-index: 2;

  .logo {
    padding: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    img {
      width: 72px;
      height: 34px;
    }
  }
}
