@use 'Typography.mixins' as *;

@use 'Typography';

@mixin theme($theme) {
  .h1 {@include h1($theme);}
  .h2 {@include h2($theme);}
  .h3 {@include h3($theme);}
  .h4 {@include h4($theme);}
  .p, .body1 {@include body1($theme);}
  .span, .body2 {@include body2($theme);}
}
