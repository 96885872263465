@use '../mixins' as *;

@mixin font($color, $size, $weight, $height, $font: sans-serif) {
  color: $color; font-size: $size; font-weight: $weight; line-height: $height; font-family: $font;
}

@mixin font-theme($theme, $level, $color: null) {
  $black: base($theme, black);
  $color: $color or $black;

  $font: font($theme, $level);
  $size: size($theme, $level);
  $weight: weight($theme, $level);
  $height: height($theme, $level);

  @include font($color, $size, $weight, $height, $font);
}

@mixin h1($theme, $color: null) {
  @include font-theme($theme, h1, $color);
}
@mixin h2($theme, $color: null) {
  @include font-theme($theme, h2, $color);
}
@mixin h3($theme, $color: null) {
  @include font-theme($theme, h3, $color);
}
@mixin h4($theme, $color: null) {
  @include font-theme($theme, h4, $color);
}
@mixin body1($theme, $color: null) {
  @include font-theme($theme, body1, $color);
}
@mixin body2($theme, $color: null) {
  @include font-theme($theme, body2, $color);
}
