:root {
  --white: #FFFFFF;
  --lightest-grey: #F6F6F6;
  --light-grey: #E0E0E0;
  --grey: #A8A8A8;
  --dark-grey: #777777;
  --black: #212121;

  --lightest-orange: #FFF8F1;
  --lighter-orange: #FFECD9;
  --light-orange: #FFE2C6;
  --orange: #FF8D1D;
  --dark-orange: #F87B00;

  --lighter-green: #EFF5CC;
  --light-green: #E5EDB8;
  --green: #ADCF00;
  --dark-green: #90AC01;

  --light-red: #FFE7E8;
  --dark-red: #F22C38;
}
