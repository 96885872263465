@mixin button-palette($color, $background, $interaction, $border: none) {
  color: $color;
  background: $background;

  @if ($border != none) {
    border: 1px solid $border;
  } @else {
    border: $border;
  }

  &:hover, &:focus, &:active {background: $interaction;}

  transition: color .2s, background .2s, border .2s;
}
