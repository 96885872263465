@use '../Typography/Typography.mixins' as *;
@use '../mixins' as *;

@use 'Tabs';

@mixin theme($theme) {
  $white: base($theme, white);
  $base-light: base($theme, light);
  $base-dark: base($theme, dark);
  $primary: primary($theme);

  .TabLabel {
    @include body2($theme);
  }

  .TabLabel {
    background: $white;
    color: $base-dark;
    padding-bottom: 8px + 6px;
  }
  .TabLabel:after {border-bottom: 2px solid $base-light;}

  .TabLabel-active {color: $primary;}
  .TabLabel-active:after {border-bottom: 6px solid $primary;}
}
