.ExpansionPanel {
  // todo: theming
  background: var(--white);
}

.ExpansionPanel-active {
  position: absolute;
  left: 0; right: 0; bottom: 0; top: 0;
  z-index: 10;

  .ExpansionPanelTitle-icon {transform: rotate(90deg);}
}

.ExpansionPanelTitle {
  display: flex; align-items: center;
  font-size: 24px;
  padding: 16px;
  border: 1px solid var(--light-grey);
  border-radius: 16px;
  cursor: pointer;
}

.ExpansionPanelTitle-icon {
  flex-shrink: 0;
  margin-right: 16px;
}

.ExpansionPanelContent {
  margin-top: 16px;
}
