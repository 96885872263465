.Layout {
  display: flex;
  height: 100%;

  .content {
    position: relative;
    padding: 24px;
    width: calc(100% - 104px);
  }
}
