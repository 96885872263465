.Tabs {display: flex; flex-direction: column;}
.TabsHeader {
  display: flex;

  .TabLabel {width: 100%;}
}

.Tab {height: 100%;}
.TabLabel {
  position: relative;
  padding: 8px 0;

  letter-spacing: .13em;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {cursor: auto;}
}
.TabLabel:after {
  content: '';
  position: absolute; left: 0; bottom: 0;
  width: 100%;
}
.TabLabel-active {}
