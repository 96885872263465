.Button {
  padding: 24px;
  border-radius: 16px;

  letter-spacing: .13em;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {cursor: auto;}
}
