@use 'sass:map';

@mixin normalize() {
  margin: 0; padding: 0;
}

@function color($theme, $palette: base, $hue: normal) {
  $palettes: map.get($theme, palettes);
  $config: map.get($palettes, $palette);
  @return map.get($config, $hue);
}

@function base($theme, $hue: normal) {
  @return color($theme, base, $hue);
}

@function primary($theme, $hue: normal) {
  @return color($theme, primary, $hue);
}

@function accent($theme, $hue: normal) {
  @return color($theme, accent, $hue);
}

@function typography($theme, $level) {
  $typography: map.get($theme, typography);
  $config: map.get($typography, $level);
  @return $config;
}

@function font($theme, $level) {
  @return map.get(typography($theme, $level), font);
}

@function size($theme, $level) {
  @return map.get(typography($theme, $level), size);
}

@function weight($theme, $level) {
  @return map.get(typography($theme, $level), weight);
}

@function height($theme, $level) {
  @return map.get(typography($theme, $level), height);
}
