@use 'Button.mixins' as *;
@use '../Typography/Typography.mixins' as *;
@use '../mixins' as *;

@use 'Button';

@mixin theme($theme) {
  $white: base($theme, white);
  $black: base($theme, black);

  $base-lightest: base($theme, lightest);
  $base-light: base($theme, light);
  $base: base($theme);

  $primary-lighter: primary($theme, lighter);
  $primary-light: primary($theme, light);
  $primary: primary($theme);
  $primary-dark: primary($theme, dark);

  $accent-lighter: accent($theme, lighter);
  $accent-light: accent($theme, light);
  $accent: accent($theme);
  $accent-dark: accent($theme, dark);

  .Button {
    @include body2($theme);
  }

  .Button {
    @include button-palette($black, $white, $base-lightest);
  }
  .Button.primary {
    @include button-palette($white, $primary, $primary-dark);
  }
  .Button.accent {
    @include button-palette($white, $accent, $accent-dark);
  }

  .Button.light.primary {
    @include button-palette($primary-dark, $primary-lighter, $primary-light);
  }
  .Button.light.accent {
    @include button-palette($accent-dark, $accent-lighter, $accent-light);
  }

  .Button, .Button.light {
    &:disabled {
      @include button-palette($white, $base-light, $base-light);
    }
  }

  .Button.outlined {
    @include button-palette($black, transparent, $base-lightest, $base);
  }
  .Button.outlined.primary {
    @include button-palette($primary-dark, transparent, $primary-lighter, $primary);
  }
  .Button.outlined.accent {
    @include button-palette($accent-dark, transparent, $accent-lighter, $accent);
  }

  .Button.outlined {
    &:disabled {
      @include button-palette($base, transparent, transparent, $base-light);
    }
  }

  .Button.outlined.primary, .Button.outlined.accent {
    border-width: 3px;
    &:disabled {color: $base-light;}
  }

  .Button.clear {
    @include button-palette(transparent, transparent, transparent, transparent);
    padding: 0;
  }
}
