.SideMenuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 24px 32px;
  cursor: pointer;

  &.active {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 100%;
      left: 0;
      background: #FF8D1D;
      border-radius: 0 8px 8px 0;
    }

    svg path {
      fill: #FF8D1D;
    }
  }
}
