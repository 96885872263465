@import "normalize";
@import "colors.css";

@import 'libs/ui/src/lib/app-smart.theme';

html {
  font-family: 'Lato', sans-serif;
}

html, body, #root{
  height: 100%;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

button {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
